import { connect } from 'react-redux'
import {
  getAllTemplates,
  getAllProjects,
  addNewTemplate,
  updateTemplate,
  addNewProject,
  deleteTemplate,
  getWMMappingById,
  addNewWMMapping,
  updateWMMapping,
  deleteWMMapping,
} from './action'
import { alertActions } from '../../app-common/common-components/app-alert/alert-action'
import Dashboard from './component'
import { getTemplatesSelector, getProjectsSelector } from './selector'

const mapStateToProps = (state, props) => {
  const { templateReducer } = state
  const templateData = getTemplatesSelector(templateReducer)
  const data = {
    fetchCompleted: state.templateReducer.completed,
    allTemplates: templateData,
    fetchProjectCompleted: state.projectReducer.completed,
    allProjects: state.projectReducer.projects,
    wmMapping: state.wmMappingReducer,
  }
  return data
}

const mapDispatchToProps = (dispatch) => ({
  getTemplateList: (projectId) => {
    dispatch(getAllTemplates(projectId))
  },
  getProjectList: () => {
    dispatch(getAllProjects())
  },
  addNewProject: (payload) => {
    dispatch(addNewProject(payload))
  },
  addNewTemplate: (projId, payload) => {
    dispatch(addNewTemplate(projId, payload))
  },
  successAlert: (msg) => {
    dispatch(alertActions.success(msg))
  },
  errorAlert: (msg) => {
    dispatch(alertActions.error(msg))
  },
  updateTemplate: (projId, tempId, payload) => {
    dispatch(updateTemplate(projId, tempId, payload))
  },
  deleteTemplate: (projId, tempId) => {
    dispatch(deleteTemplate(projId, tempId))
  },
  //WireMock
  getMappingById: (id) => {
    dispatch(getWMMappingById(id))
  },
  addNewWMMapping: (projectId, mapping) => {
    dispatch(addNewWMMapping(projectId, mapping))
  },
  updateWMMapping: (projectId, templateId, mappingId, mapping) => {
    dispatch(updateWMMapping(projectId, templateId, mappingId, mapping))
  },
  deleteWMMapping: (projectId, templateId, mappingId) => {
    dispatch(deleteWMMapping(projectId, templateId, mappingId))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
