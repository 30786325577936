import { reducerConst } from '../../config'

export function templateReducer(state = {}, action) {
  switch (action.type) {
    case reducerConst.GET_TEMPLATES:
      return { ...state, completed: false }
    case reducerConst.GET_TEMPLATES_SUCCESS:
      return { ...state, completed: true, templates: action.payload }
    case reducerConst.GET_TEMPLATES_FAILURE:
      return { ...state, completed: true, error: action.payload }
    default:
      return state
  }
}

export function projectReducer(state = {}, action) {
  switch (action.type) {
    case reducerConst.GET_PROJECTS:
      return { ...state, completed: false }
    case reducerConst.GET_PROJECTS_SUCCESS:
      return { ...state, completed: true, projects: action.payload }
    case reducerConst.GET_PROJECTS_FAILURE:
      return { ...state, completed: true, error: action.payload }
    default:
      return state
  }
}

export function addTemplateReducer(state = {}, action) {
  switch (action.type) {
    case reducerConst.ADD_TEMPLATE:
      return { ...state, completed: false }
    case reducerConst.ADD_TEMPLATE_SUCCESS:
      return { ...state, completed: true, data: action.payload }
    case reducerConst.ADD_TEMPLATE_FAILURE:
      return { ...state, completed: true, error: action.payload }
    default:
      return state
  }
}

export function updateTemplateReducer(state = {}, action) {
  switch (action.type) {
    case reducerConst.UPDATE_TEMPLATE:
      return { ...state, completed: false }
    case reducerConst.UPDATE_TEMPLATE_SUCCESS:
      return { ...state, completed: true, data: action.payload }
    case reducerConst.UPDATE_TEMPLATE_FAILURE:
      return { ...state, completed: true, error: action.payload }
    default:
      return state
  }
}

export function deleteTemplateReducer(state = {}, action) {
  switch (action.type) {
    case reducerConst.DELETE_TEMPLATE:
      return { ...state, completed: false }
    case reducerConst.DELETE_TEMPLATE_SUCCESS:
      return { ...state, completed: true, data: action.payload }
    case reducerConst.DELETE_TEMPLATE_FAILURE:
      return { ...state, completed: true, error: action.payload }
    default:
      return state
  }
}

export function addProjectReducer(state = {}, action) {
  switch (action.type) {
    case reducerConst.ADD_PROJECT:
      return { ...state, completed: false }
    case reducerConst.ADD_PROJECT_SUCCESS:
      return { ...state, completed: true, data: action.payload }
    case reducerConst.ADD_PROJECT_FAILURE:
      return { ...state, completed: true, error: action.payload }
    default:
      return state
  }
}

export function wmMappingReducer(state = {}, action) {
  switch (action.type) {
    case reducerConst.GET_WM_MAPPING_ID:
      return { ...state, completed: false }
    case reducerConst.GET_WM_MAPPING_ID_SUCCESS:
      return { ...state, completed: true, mapping: action.payload }
    case reducerConst.GET_WM_MAPPING_ID_FAILURE:
      return { ...state, completed: true, error: action.payload }
    default:
      return state
  }
}

export function addWMMappingReducer(state = {}, action) {
  switch (action.type) {
    case reducerConst.ADD_WM_MAPPING:
      return { ...state, completed: false }
    case reducerConst.ADD_WM_MAPPING_SUCCESS:
      return { ...state, completed: true, data: action.payload }
    case reducerConst.ADD_WM_MAPPING_FAILURE:
      return { ...state, completed: true, error: action.payload }
    default:
      return state
  }
}

export function updateWMMappingReducer(state = {}, action) {
  switch (action.type) {
    case reducerConst.UPDATE_WM_MAPPING:
      return { ...state, completed: false }
    case reducerConst.UPDATE_WM_MAPPING_SUCCESS:
      return { ...state, completed: true, data: action.payload }
    case reducerConst.UPDATE_WM_MAPPING_FAILURE:
      return { ...state, completed: true, error: action.payload }
    default:
      return state
  }
}

export function deleteWMMappingReducer(state = {}, action) {
  switch (action.type) {
    case reducerConst.DELETE_WM_MAPPING:
      return { ...state, completed: false }
    case reducerConst.DELETE_WM_MAPPING_SUCCESS:
      return { ...state, completed: true, data: action.payload }
    case reducerConst.DELETE_WM_MAPPING_FAILURE:
      return { ...state, completed: true, error: action.payload }
    default:
      return state
  }
}
