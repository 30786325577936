import React from 'react'
import TreeView from '@material-ui/lab/TreeView'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import TreeItem from '@material-ui/lab/TreeItem'
import Typography from '@material-ui/core/Typography'
import { config } from '../../config'

function HelpTree() {
  return (
    <TreeView
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
    >
      <TreeItem nodeId="1" label={<b>repeat</b>}>
        <Typography variant="body2" gutterBottom>
          Specifies number of repeats of array item. Repeatable array must
          contains only two items: first is repeat tag, second is the item that
          must be repeated. Along with the repeat tag the repitation clount
          argument need to be defined. Repeat count argument can be defined as{' '}
          <b>
            <em>##count##</em>
          </b>{' '}
          to make the item repitation set by parameter passed during the API
          call. If argument is specified, item will be repeated that many times.
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          <b>USAGE: </b>
          <p>
            <code>{'{{#repeat ##count##}} [item...] {{/repeat}}'} </code>
          </p>
          <p>OR</p>
          <p>
            <code> {'{{#repeat [count]}} [item...] {{/repeat}}'}</code>
          </p>
          <p>
            <b>Parameters: </b>
          </p>
          <p>
            <code>count [number]</code> : Repitation count for the item. Default
            is 10 if not passed as API parameter.
          </p>
        </Typography>
      </TreeItem>
      <TreeItem nodeId="2" label={<b>random</b>}>
        <Typography variant="body2" gutterBottom>
          Returns random item from passed arguments list.
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          <b>USAGE: </b>
          <p>
            <code>{'{{random [arg1] [arg2] ... [argN]}}'}</code>
          </p>
          <p>
            <b>Parameters: </b>
          </p>
          <p>
            <code>arg [array]</code> : Array of string or number
          </p>
        </Typography>
      </TreeItem>
      <TreeItem nodeId="3" label={<b>int</b>}>
        <Typography variant="body2" gutterBottom>
          Random integer in specified range.
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          <b>USAGE: </b>
          <p>
            <code>{'{{integer [min] [max] [format] }}'} </code>
          </p>
          <p>
            <b>Parameters: </b>
          </p>
          <p>
            <code>min [number]</code> : Minimum number in the range
          </p>
          <p>
            <code>max [number]</code> : Maximum number in the range
          </p>
          <p>
            <code>format [string, optional]</code> : Number format. For more
            details go to{' '}
            <a href={config.numbrojsHelp} target="_blank">
              <em>here</em>
            </a>
          </p>
        </Typography>
      </TreeItem>
      <TreeItem nodeId="4" label={<b>float</b>}>
        <Typography variant="body2" gutterBottom>
          Random float in specified range
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          <b>USAGE: </b>
          <p>
            <code>{'{{float [min] [max] [format] }}'} </code>
          </p>
          <p>
            <b>Parameters: </b>
          </p>
          <p>
            <code>min [number]</code> : Minimum number in the range
          </p>
          <p>
            <code>max [number]</code> : Maximum number in the range
          </p>
          <p>
            <code>format [string, optional]</code> : Number format. For more
            details go to{' '}
            <a href={config.numbrojsHelp} target="_blank">
              <em>here</em>
            </a>
          </p>
        </Typography>
      </TreeItem>
      <TreeItem nodeId="5" label={<b>boolean</b>}>
        <Typography variant="body2" gutterBottom>
          Random boolean value.
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          <b>USAGE: </b>
          <p>
            <code>{'{{boolean}}'} </code>
          </p>
        </Typography>
      </TreeItem>
      <TreeItem nodeId="6" label={<b>date</b>}>
        <Typography variant="body2" gutterBottom>
          Random date in specified range.
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          <b>USAGE: </b>
          <p>
            <code>{'{{date [min] [max] [format] }}'} </code>
          </p>
          <p>
            <b>Parameters: </b>
          </p>
          <p>
            <code>min [date]</code> : Minimum date in the range
          </p>
          <p>
            <code>max [date]</code> : Maximum date in the range
          </p>
          <p>
            <code>format [string, optional]</code> : Date format. For more
            details go to{' '}
            <a href={config.fechaHelp} target="_blank">
              <em>here</em>
            </a>
          </p>
        </Typography>
      </TreeItem>
      <TreeItem nodeId="7" label={<b>string</b>}>
        <Typography variant="body2" gutterBottom>
          Random string. Bay default returns 7 characters long string. If string
          length argumet is specified, random string value will be returend of
          the given length.
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          <b>USAGE: </b>
          <p>
            <code>{'{{string }}'} </code>
          </p>
          <p>OR</p>
          <p>
            <code>{'{{string  [length]}}'} </code>
          </p>
          <p>
            <b>Parameters: </b>
          </p>
          <p>
            <code>length [number, optional]</code> : Length of the string.
            Default length is 7.
          </p>
        </Typography>
      </TreeItem>
      <TreeItem nodeId="8" label={<b>index</b>}>
        <Typography variant="body2" gutterBottom>
          Return the index of the current object startting from given value. The
          index helper can only be used inside #repeat blocks.
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          <b>USAGE: </b>
          <p>
            <code>{'{{index startFrom stepSize}}'} </code>
          </p>
          <p>
            <b>Parameters: </b>
          </p>
          <p>
            <code>startFrom [number]</code> : Index will start from this value.
          </p>
          <p>
            <code>stepSize [number]</code> : Index will increment based on
            provided step value.
          </p>
        </Typography>
      </TreeItem>
      <TreeItem nodeId="9" label={<b>firstName</b>}>
        <Typography variant="body2" gutterBottom>
          Random person's first name
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          <b>USAGE: </b>
          <p>
            <code>{'{{firstName}}'} </code>
          </p>
        </Typography>
      </TreeItem>
      <TreeItem nodeId="10" label={<b>lastName</b>}>
        <Typography variant="body2" gutterBottom>
          Random person's last name
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          <b>USAGE: </b>
          <p>
            <code>{'{{lastName}}'} </code>
          </p>
        </Typography>
      </TreeItem>
      <TreeItem nodeId="11" label={<b>email</b>}>
        <Typography variant="body2" gutterBottom>
          Generates email based on firstName, surname and company that are
          called before of it.
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          <b>USAGE: </b>
          <p>
            <code>{'{{email}}'} </code>
          </p>
        </Typography>
      </TreeItem>
      <TreeItem nodeId="12" label={<b>street</b>}>
        <Typography variant="body2" gutterBottom>
          Random UK street name.
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          <b>USAGE: </b>
          <p>
            <code>{'{{street}}'} </code>
          </p>
        </Typography>
      </TreeItem>
      <TreeItem nodeId="13" label={<b>city</b>}>
        <Typography variant="body2" gutterBottom>
          Random UK city name.
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          <b>USAGE: </b>
          <p>
            <code>{'{{city}}'} </code>
          </p>
        </Typography>
      </TreeItem>
      <TreeItem nodeId="14" label={<b>postalcode</b>}>
        <Typography variant="body2" gutterBottom>
          Random UK postalcode name.
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          <b>USAGE: </b>
          <p>
            <code>{'{{postalcode}}'} </code>
          </p>
        </Typography>
      </TreeItem>
      <TreeItem nodeId="15" label={<b>county</b>}>
        <Typography variant="body2" gutterBottom>
          Random UK county name.
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          <b>USAGE: </b>
          <p>
            <code>{'{{county}}'} </code>
          </p>
        </Typography>
      </TreeItem>
      <TreeItem nodeId="16" label={<b>phone</b>}>
        <Typography variant="body2" gutterBottom>
          Generates random phone number.
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          <b>USAGE: </b>
          <p>
            <code>{'{{phone [format]}}'} </code>
          </p>
          <p>
            <b>Parameters: </b>
          </p>
          <p>
            <code>format [string]</code> : Format string which contains x
            letters. Default is "xxx-xxx-xxxx".
          </p>
        </Typography>
      </TreeItem>
      <TreeItem nodeId="17" label={<b>paragraph</b>}>
        <Typography variant="body2" gutterBottom>
          Random{' '}
          <a href={config.lipsumHelp} target="_blank">
            <em>Lorem Ipsum</em>
          </a>{' '}
          text.
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          <b>USAGE: </b>
          <p>
            <code>{'{{paragraph [count]}}'} </code>
          </p>
          <p>OR</p>
          <p>
            <code>{'{{paragraph [min] [max]}}'} </code>
          </p>
          <p>
            <b>Parameters: </b>
          </p>
          <p>
            <code>count [number]</code> : Number of word count
          </p>
          <p>
            <code>min [number]</code> : Minimum word count
          </p>
          <p>
            <code>max [number]</code> : Maximum word count
          </p>
        </Typography>
      </TreeItem>
    </TreeView>
  )
}

export default HelpTree
