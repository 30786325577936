import React, { useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import MaterialTable from 'material-table'
import Progress from '../../app-common/common-components/app-progress/progress'
import {
  AppBar,
  IconButton,
  makeStyles,
  Slide,
  Toolbar,
  Divider,
  Card,
  CardContent,
  FormControl,
  Select,
  MenuItem,
  CardHeader,
  DialogTitle,
  InputAdornment,
  Tooltip,
  DialogContentText,
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import CircularProgress from '@material-ui/core/CircularProgress'
import DialogContent from '@material-ui/core/DialogContent'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'
import PostAddIcon from '@material-ui/icons/PostAdd'
import AssignmentTurnedInOutlined from '@material-ui/icons/AssignmentTurnedInOutlined'
import { green } from '@material-ui/core/colors'
import Editor from 'react-simple-code-editor'
import { highlight, languages } from 'prismjs/components/prism-core'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-javascript'
import 'prismjs/themes/prism.css'
import Download from '../download/download'
import Sftp from '../sftp/sftp'
import ServiceBus from '../servicebus'
import HelpTree from '../help/helptree'
import BaseWMMapping from '../wiremock/wm-mapping'
import { downloadDataApi } from '../../api/api-details'
import { config } from '../../config'
import tableIcons from '../../app-common/common-components/tableIcons'

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  appBar: {
    position: 'fixed',
    width: '100%',
  },
  templateAppBar: {
    position: 'retative',
  },
  content: {
    paddingTop: '64px',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  templateDiv: {
    margin: '2%',
  },
  formControl: {
    minWidth: 200,
  },
  input: {
    width: 250,
  },
  templateEditor: {
    fontFamily: '"Fira code", "Fira Mono", monospace',
    fontSize: 14,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  divider: {
    height: 28,
    marginRight: 12,
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

function Dashboard(props) {
  const classes = useStyles()
  const templatePlaceholder = `[\n    {{#repeat ##count##}}\n        {template...}\n    {{/repeat}}\n]`
  const [open, setTeplateDialogOpen] = useState(false)
  const [openProject, setOpenProject] = useState(false)
  const [template, setTemplate] = React.useState(templatePlaceholder)
  const [pageSize, setPageSize] = React.useState(5)
  const [wiremockMapping, setWiremockMapping] = React.useState(null)
  const [allProjects, setAllProjects] = useState([])
  const [projectId, setProjectId] = useState('-1')
  const [projectName, setProjectName] = useState(null)
  const [project, setProject] = useState(null)
  const [projectError, setProjectError] = useState(false)
  const [projectErrorText, setProjectErrorText] = useState(null)
  const [templateName, setTemplateName] = useState(null)
  const [templateId, setTemplateId] = useState(null)
  const [templateType, setTemplateType] = useState('-1')
  const [addTemplateMode, setAddTemplateMode] = useState(true)
  const [openHelp, setOpenHelp] = useState(false)
  const [delConfirm, setDelConfirm] = React.useState(false)
  const [delTemplate, setDelTemplate] = React.useState(null)
  const columns = [
    {
      title: 'Template',
      field: 'name',
      render: (rowData) => (
        <Typography variant="body2" gutterBottom>
          {rowData.name}
        </Typography>
      ),
    },
    {
      title: 'API',
      render: (rowData) => genrateDataApi(rowData),
      sorting: false,
    },
    {
      title: 'Download',
      render: (rowData) => generateDownloadBtn(rowData),
      sorting: false,
    },
    {
      title: 'Service Bus',
      render: (rowData) => generateSbBtn(rowData),
      sorting: false,
    },
    {
      title: 'SFTP',
      render: (rowData) => generateSFTPBtn(rowData),
      sorting: false,
    },
    {
      title: 'Action',
      type: 'numeric',
      render: (rowData) => generateActionBtn(rowData),
      sorting: false,
    },
  ]

  useEffect(() => {
    if (!props.allProjects) {
      props.getProjectList()
    }
    if (props.fetchProjectCompleted) {
      const { allProjects } = props
      const templateData = Object.entries(allProjects.data).map(
        ([key, value]) => {
          return {
            key: key,
            name: value.projectName,
          }
        }
      )
      setAllProjects(templateData)
    }
  }, [props.fetchProjectCompleted, props.allProjects])

  const openTeplateDialog = () => {
    setAddTemplateMode(true)
    setTeplateDialogOpen(true)
    //setWMTemplate(wmTemplatePlaceholder)
  }

  const handleTeplateDialogClose = () => {
    setTeplateDialogOpen(false)
    setTemplateName(null)
    setTemplateId(null)
    setTemplate(templatePlaceholder)
    setTemplateType('-1')
  }

  const handleProjectDialogClose = () => {
    setProject(null)
    setOpenProject(false)
    setProjectError(false)
    setProjectErrorText(null)
  }

  const handleTemplateTypeChange = (e) => {
    setTemplateType(e.target.value)
  }

  const handleProjectChange = (e) => {
    setProjectId(e.target.value)
    props.getTemplateList(e.target.value)
    const project = allProjects.find((item) => item.key === e.target.value)
    setProjectName(project.name)
  }

  const handleProjectNameChange = (e) => {
    setProject(e.target.value)
    if (e.target.value.trim().length === 0) {
      setProjectError(true)
      setProjectErrorText('Must enter a valid project name')
    } else {
      setProjectError(false)
      setProjectErrorText('')
    }
  }

  const handleAddProject = () => {
    setOpenProject(true)
  }

  const addNewProject = () => {
    const projectData = { projectName: project }
    props.addNewProject(projectData)
    setProject(null)
    setOpenProject(false)
    setProjectError(false)
    setProjectErrorText(null)
  }

  const handleClipboardClick = (link) => {
    navigator.clipboard.writeText(link)
  }

  const handleHelpViewClose = () => {
    setOpenHelp(false)
  }

  const handleHelpViewOpen = () => {
    setOpenHelp(true)
  }

  const handleEditTemplate = (data) => {
    setAddTemplateMode(false)
    setTeplateDialogOpen(true)
    setTemplateName(data.name)
    setTemplateId(data.key)
    setTemplateType(data.type)
    if (data.type === config.wiremock) {
      //setWMTemplate(null)
      props.getMappingById(data.template)
    } else {
      setTemplate(data.template)
    }
  }

  const handleDeleteTemplate = (data) => {
    const delTemplate = {
      projectId: data.projectId,
      templateId: data.key,
      templateName: data.name,
      templateType: data.type,
      mappingId: data.type === config.wiremock ? data.template : null,
    }
    setDelTemplate(delTemplate)
    setDelConfirm(true)
  }

  const deleteTemplate = () => {
    setDelConfirm(false)
    setDelTemplate(null)
    if (delTemplate.templateType === config.wiremock) {
      props.deleteWMMapping(
        delTemplate.projectId,
        delTemplate.templateId,
        delTemplate.mappingId
      )
    } else {
      props.deleteTemplate(delTemplate.projectId, delTemplate.templateId)
    }
  }

  const handleDelConfirmClose = () => {
    setDelConfirm(false)
    setDelTemplate(null)
  }

  const addNewTemplate = () => {
    if (templateType === config.wiremock) {
      const mapping = { ...wiremockMapping, name: templateName }
      props.addNewWMMapping(projectId, mapping)
    } else {
      const templateData = {
        templateName: templateName,
        template: template,
        projectId: projectId,
        templateType: templateType,
      }
      props.addNewTemplate(projectId, templateData)
    }

    setTemplate(templatePlaceholder)
    setTemplateName(null)
    setTemplateId(null)
    handleTeplateDialogClose()
  }

  const updateTemplate = () => {
    if (templateType === config.wiremock) {
      const mapping = { ...wiremockMapping, name: templateName }
      props.updateWMMapping(projectId, templateId, wiremockMapping.id, mapping)
    } else {
      const templateData = {
        templateName: templateName,
        template: template,
        projectId: projectId,
        templateType: templateType,
      }
      props.updateTemplate(projectId, templateId, templateData)
    }
    setTemplate(templatePlaceholder)
    setTemplateName(null)
    setTemplateId(null)
    handleTeplateDialogClose()
  }

  const generateDownloadBtn = (rowData) => {
    return (
      <Download
        data={rowData}
        successAlert={props.successAlert}
        errorAlert={props.errorAlert}
      />
    )
  }

  const generateSFTPBtn = (rowData) => {
    return <Sftp data={rowData}></Sftp>
  }

  const generateSbBtn = (rowData) => {
    return <ServiceBus data={rowData}></ServiceBus>
  }

  const generateActionBtn = (rowData) => {
    return (
      <div>
        <IconButton
          size="small"
          onClick={() => handleEditTemplate(rowData)}
          color="primary"
        >
          <Tooltip title={`Edit template - ${rowData.name}`} placement="bottom">
            <EditIcon />
          </Tooltip>
        </IconButton>
        <IconButton
          size="small"
          onClick={() => handleDeleteTemplate(rowData)}
          color="secondary"
        >
          <Tooltip
            title={`Delete template - ${rowData.name}`}
            placement="bottom"
          >
            <DeleteIcon />
          </Tooltip>
        </IconButton>
      </div>
    )
  }

  const genrateDataApi = (rowData) => {
    return rowData.type === config.wiremock ? null : (
      <TextField
        disabled
        className={classes.input}
        size="small"
        variant="outlined"
        id="projectName"
        name="projectName"
        value={downloadDataApi(
          rowData.projectId,
          rowData.key,
          rowData.type,
          10
        )}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Divider className={classes.divider} orientation="vertical" />
              <IconButton
                size="small"
                color="primary"
                onClick={() => {
                  handleClipboardClick(
                    downloadDataApi(
                      rowData.projectId,
                      rowData.key,
                      rowData.type,
                      10
                    )
                  )
                }}
              >
                <Tooltip title="Copy to Clipboard" placement="bottom">
                  <AssignmentTurnedInOutlined />
                </Tooltip>
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    )
  }

  const addProjectDialog = (
    <Dialog
      open={openProject}
      onClose={handleProjectDialogClose}
      aria-labelledby="form-dialog-title"
      TransitionComponent={Transition}
    >
      <DialogTitle id="form-dialog-title">Add new Project</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To add a new project please provide the project name and create.
        </DialogContentText>
        <TextField
          required
          autoFocus
          variant="outlined"
          id="projectName"
          name="projectName"
          label="Project Name"
          margin="dense"
          value={project}
          size="small"
          onChange={handleProjectNameChange}
          fullWidth
          error={projectError}
          helperText={projectErrorText}
        />
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          color="secondary"
          variant="outlined"
          onClick={handleProjectDialogClose}
        >
          Cancel
        </Button>
        <Button
          size="small"
          color="primary"
          variant="outlined"
          onClick={addNewProject}
          disabled={projectError}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  )

  const deleteConfirm = (
    <Dialog
      open={delConfirm}
      onClose={handleDelConfirmClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      TransitionComponent={Transition}
    >
      <DialogTitle id="alert-dialog-title">{'Please Confirm'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {delTemplate && delTemplate.templateName} will be deleted permanently.
          Are you sure to delete the template?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleDelConfirmClose}
          color="secondary"
          size="small"
          variant="outlined"
        >
          Disagree
        </Button>
        <Button
          onClick={deleteTemplate}
          color="primary"
          autoFocus
          size="small"
          variant="outlined"
        >
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  )

  const addEditTemplate = (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      onClose={handleTeplateDialogClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.templateAppBar} position="static">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            {projectName} -{' '}
            {addTemplateMode ? ' Add New Template' : 'Update Template'}
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleTeplateDialogClose}
            aria-label="close"
          >
            <CloseIcon></CloseIcon>
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              disabled
              variant="outlined"
              id="projectName"
              name="projectName"
              label="Project Name"
              value={projectName}
              size="small"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              required
              variant="outlined"
              id="templateName"
              name="templateName"
              label="Template Name"
              value={templateName}
              size="small"
              onChange={(e) => setTemplateName(e.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl
              variant="outlined"
              size="small"
              className={classes.formControl}
            >
              <Select
                id="type-dd"
                name="type-dd"
                value={templateType}
                onChange={handleTemplateTypeChange}
              >
                <MenuItem value={-1}>
                  <em>Select Teplate Type</em>
                </MenuItem>
                <MenuItem value={config.json}>JSON</MenuItem>
                <MenuItem value={config.csv}>CSV</MenuItem>
                <MenuItem value={config.xml}>XML</MenuItem>
                <MenuItem value={config.wiremock}>WireMock</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Typography variant="body1">
                Template :
                {templateType === config.wiremock ? (
                  <span>
                    <Typography variant="caption" display="block" gutterBottom>
                      Need help? Find the WireMock documentation
                      <a href={config.wiremockHelp} target="_blank">
                        <IconButton size="small" color="primary">
                          <HelpOutlineIcon />
                        </IconButton>
                      </a>
                    </Typography>
                  </span>
                ) : (
                  <span>
                    <Typography variant="caption" display="block" gutterBottom>
                      Need help? Please explore the available template tags
                      <IconButton
                        size="small"
                        onClick={handleHelpViewOpen}
                        color="primary"
                      >
                        <HelpOutlineIcon />
                      </IconButton>
                    </Typography>
                  </span>
                )}
              </Typography>
            </Grid>
            <Grid>
              {templateType === config.wiremock ? (
                addTemplateMode ? (
                  <BaseWMMapping
                    addMapping={true}
                    setWiremockMapping={setWiremockMapping}
                  ></BaseWMMapping>
                ) : props.wmMapping.completed ? (
                  <BaseWMMapping
                    addMapping={false}
                    mapping={props.wmMapping.mapping}
                    setWiremockMapping={setWiremockMapping}
                  ></BaseWMMapping>
                ) : (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )
              ) : (
                <Editor
                  value={template}
                  onValueChange={(code) => setTemplate(code)}
                  highlight={(code) => highlight(code, languages.js)}
                  padding={10}
                  variant="outlined"
                  className={classes.templateEditor}
                ></Editor>
              )}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          size="small"
          color="secondary"
          variant="outlined"
          onClick={handleTeplateDialogClose}
        >
          Cancel
        </Button>
        {addTemplateMode ? (
          <Button
            size="small"
            color="primary"
            variant="outlined"
            onClick={addNewTemplate}
          >
            Create
          </Button>
        ) : (
          <Button
            size="small"
            color="primary"
            variant="outlined"
            onClick={updateTemplate}
          >
            Update
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )

  const projectDropDown = (
    <div>
      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
        <Select
          variant="outlined"
          id="project-dd"
          name="project-dd"
          value={projectId}
          onChange={handleProjectChange}
        >
          <MenuItem value={-1}>
            <em>Select Project</em>
          </MenuItem>
          {allProjects.map((value) => {
            return <MenuItem value={value.key}>{value.name}</MenuItem>
          })}
        </Select>
      </FormControl>
    </div>
  )

  const allTemplatesTable = (
    <div>
      {!props.fetchCompleted ? (
        <Progress />
      ) : (
        <MaterialTable
          icons={tableIcons}
          title={`${projectName} - Templates`}
          columns={columns}
          data={props.allTemplates}
          onChangeRowsPerPage={(event) => {setPageSize(event)}}
          options={{
            padding: 'dense',
            actionsColumnIndex: 0,
            pageSize: pageSize,
          }}
          actions={[
            {
              icon: 'add',
              tooltip: 'Add New Template',
              isFreeAction: true,
              onClick: (event) => {
                openTeplateDialog()
              },
            },
          ]}
          components={{
            Action: (props) => (
              <Tooltip
                title={`Add new template for ${projectName}`}
                placement="bottom"
              >
                <Button
                  onClick={(event) => props.action.onClick()}
                  color="primary"
                  variant="outlined"
                  style={{ marginLeft: 15 }}
                  size="small"
                  startIcon={<AddBoxOutlinedIcon />}
                >
                  ADD TEMPLATE
                </Button>
              </Tooltip>
            ),
          }}
        ></MaterialTable>
      )}
    </div>
  )

  const helpTreeView = (
    <Dialog
      open={openHelp}
      fullWidth
      maxWidth="sm"
      onClose={handleHelpViewClose}
      aria-labelledby="customized-dialog-title"
    >
      <DialogTitle id="customized-dialog-title">
        <Typography variant="h6">List of template tags</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleHelpViewClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers={true}>
        <HelpTree />
      </DialogContent>
    </Dialog>
  )

  return (
    <div className={classes.grow}>
      <AppBar className={classes.appBar} position="fixed">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Stubbing Tool
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.content}>
        <Grid container>
          <Grid item xs={12}>
            <Card>
              <CardHeader
                avatar={projectDropDown}
                action={
                  <div style={{ paddingTop: '10px' }}>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={handleAddProject}
                      startIcon={<PostAddIcon />}
                    >
                      Add New Project
                    </Button>
                  </div>
                }
              />
              <CardContent>
                <Grid item xs={12}>
                  <div>
                    {!props.fetchCompleted ? <div /> : allTemplatesTable}
                  </div>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
      {addEditTemplate}
      {addProjectDialog}
      {helpTreeView}
      {deleteConfirm}
    </div>
  )
}

export default Dashboard
