import ServiceBus from './component'
import { connect } from 'react-redux'
import { getEnvironments, getTopics, sendMessagesToSb } from './action'

const mapStateToProps = (state, props) => {
  const data = {
    getEnvCompleted: state.environmentReducer.completed,
    environments: state.environmentReducer.environments,
    getTopicCompleted: state.topicReducer.completed,
    topics: state.topicReducer.topics,
    sendMessageInprogress: state.sendMessageReducer.inprogress,
  }
  return data
}

const mapDispatchToProps = (dispatch) => ({
  getEnvironments: () => {
    dispatch(getEnvironments())
  },
  getTopics: (env) => {
    dispatch(getTopics(env))
  },
  sendMessage: (reqBody) => {
    dispatch(sendMessagesToSb(reqBody))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ServiceBus)
