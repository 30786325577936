import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import Editor from 'react-simple-code-editor'
import { highlight, languages } from 'prismjs/components/prism-core'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-javascript'
import 'prismjs/themes/prism.css'

const useStyles = makeStyles((theme) => ({
  templateEditor: {
    fontFamily: '"Fira code", "Fira Mono", monospace',
    fontSize: 14,
  },
}))

const useConstructor = (callBack = () => {}) => {
  const [hasBeenCalled, setHasBeenCalled] = useState(false)
  if (hasBeenCalled) return
  callBack()
  setHasBeenCalled(true)
}

function JsonMapping(props) {
  const classes = useStyles()

  useConstructor(() => {})

  return (
    <div>
      <Editor
        value={props.mapping}
        onValueChange={(code) => props.handleJsonViewChnage(code)}
        highlight={(code) => highlight(code, languages.js)}
        padding={10}
        variant="outlined"
        className={classes.templateEditor}
      ></Editor>
    </div>
  )
}

export default JsonMapping
