export const config = {
  wiremock: 'wiremock',
  json: 'json',
  csv: 'csv',
  xml: 'xml',
  wiremockHelp: 'https://wiremock.org/docs/stubbing/',
  wiremockHeaders: 'headers',
  wiremockHeadersAdd: 'add',
  wiremockHeadersUpdate: 'update',
  wiremockHeadersDelete: 'delete',
  wiremockUrlPrefix: '/wiremock/',
  numbrojsHelp: 'https://blog.stevex.net/string-formatting-in-csharp/',
  fechaHelp: 'https://blog.stevex.net/string-formatting-in-csharp/',
  lipsumHelp: 'http://www.lipsum.com/',
  stubSvrUrl: process.env.REACT_APP_STUB_SERVER_API,
}

export const reducerConst = {
  GET_TEMPLATES: 'GET_TEMPLATES',
  GET_TEMPLATES_SUCCESS: 'GET_TEMPLATES_SUCCESS',
  GET_TEMPLATES_FAILURE: 'GET_TEMPLATES_FAILURE',

  GET_PROJECTS: 'GET_PROJECTS',
  GET_PROJECTS_SUCCESS: 'GET_PROJECTS_SUCCESS',
  GET_PROJECTS_FAILURE: 'GET_PROJECTS_FAILURE',

  GET_DUMMYDATA: 'GET_DUMMYDATA',
  GET_DUMMYDATA_SUCCESS: 'GET_DUMMYDATA_SUCCESS',
  GET_DUMMYDATA_FAILURE: 'GET_DUMMYDATA_FAILURE',

  ADD_TEMPLATE: 'ADD_TEMPLATE',
  ADD_TEMPLATE_SUCCESS: 'ADD_TEMPLATE_SUCCESS',
  ADD_TEMPLATE_FAILURE: 'ADD_TEMPLATE_FAILURE',

  UPDATE_TEMPLATE: 'UPDATE_TEMPLATE',
  UPDATE_TEMPLATE_SUCCESS: 'UPDATE_TEMPLATE_SUCCESS',
  UPDATE_TEMPLATE_FAILURE: 'UPDATE_TEMPLATE_FAILURE',

  DELETE_TEMPLATE: 'DELETE_TEMPLATE',
  DELETE_TEMPLATE_SUCCESS: 'DELETE_TEMPLATE_SUCCESS',
  DELETE_TEMPLATE_FAILURE: 'DELETE_TEMPLATE_FAILURE',

  ADD_PROJECT: 'ADD_PROJECT',
  ADD_PROJECT_SUCCESS: 'ADD_PROJECT_SUCCESS',
  ADD_PROJECT_FAILURE: 'ADD_PROJECT_FAILURE',

  GET_WM_MAPPING_ID: 'GET_WM_MAPPING_ID',
  GET_WM_MAPPING_ID_SUCCESS: 'GET_WM_MAPPING_ID_SUCCESS',
  GET_WM_MAPPING_ID_FAILURE: 'GET_WM_MAPPING_ID_FAILURE',

  ADD_WM_MAPPING: 'ADD_WM_MAPPING',
  ADD_WM_MAPPING_SUCCESS: 'ADD_WM_MAPPING_SUCCESS',
  ADD_WM_MAPPING_FAILURE: 'ADD_WM_MAPPING_FAILURE',

  UPDATE_WM_MAPPING: 'UPDATE_WM_MAPPING',
  UPDATE_WM_MAPPING_SUCCESS: 'UPDATE_WM_MAPPING_SUCCESS',
  UPDATE_WM_MAPPING_FAILURE: 'UPDATE_WM_MAPPING_FAILURE',

  DELETE_WM_MAPPING: 'DELETE_WM_MAPPING',
  DELETE_WM_MAPPING_SUCCESS: 'DELETE_WM_MAPPING_SUCCESS',
  DELETE_WM_MAPPING_FAILURE: 'DELETE_WM_MAPPING_FAILURE',

  GET_ENVIRONMENTS: 'GET_ENVIRONMENTS',
  GET_ENVIRONMENTS_SUCCESS: 'GET_ENVIRONMENTS_SUCCESS',
  GET_ENVIRONMENTS_FAILURE: 'GET_ENVIRONMENTS_FAILURE',

  GET_TOPICS: 'GET_TOPICS',
  GET_TOPICS_SUCCESS: 'GET_TOPICS_SUCCESS',
  GET_TOPICS_FAILURE: 'GET_TOPICS_FAILURE',

  SEND_MESSAGES: 'SEND_MESSAGES',
  SEND_MESSAGES_SUCCESS: 'SEND_MESSAGES_SUCCESS',
  SEND_MESSAGES_FAILURE: 'SEND_MESSAGES_FAILURE',
}

export const wiremockMappingPlaceholder = {
  name: '',
  request: {
    url: '/wiremock/<unique url>',
    method: 'GET/POST/PUT/DELETE',
    headers: {},
  },
  response: {
    status: 200,
    body: '<Response Body In JSON, XML or Raw Text format>',
    headers: {},
  },
  persistent: true,
}

export const wiremockMappingSchema = {
  $schema: 'http://json-schema.org/draft-04/schema#',
  type: 'object',
  properties: {
    id: {
      type: 'string',
    },
    name: {
      type: 'string',
    },
    request: {
      type: 'object',
      properties: {
        url: {
          type: 'string',
        },
        method: {
          type: 'string',
        },
        headers: {
          type: 'object',
        },
      },
      required: ['url', 'method', 'headers'],
    },
    response: {
      type: 'object',
      properties: {
        status: {
          type: 'integer',
        },
        body: {
          type: 'string',
        },
        headers: {
          type: 'object',
        },
      },
      required: ['status', 'body', 'headers'],
    },
    uuid: {
      type: 'string',
    },
    persistent: {
      type: 'boolean',
    },
  },
  required: ['name', 'request', 'response', 'persistent'],
}
