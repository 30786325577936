import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Backdrop from '@material-ui/core/Backdrop';
import DialogContentText from '@material-ui/core/DialogContentText'
import CloseIcon from '@material-ui/icons/Close'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { green } from '@material-ui/core/colors'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles((theme) => ({
  input: {
    width: 250,
  },
  button: {
    width: 80,
    marginBottom: 5,
    marginTop: 5,
  },
  content: {
    paddingLeft: 15,
    paddingRight: 15,
  },
  backdrop: {
    zIndex: 2000,
    color: '#fff',
  },
  divider: {
    height: 24,
    marginRight: 12,
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '100%',
  },
  buttonProgressLeft: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '0%',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  templateAppBar: {
    position: 'retative',
  },
}))

function ServiceBus(props) {
  const { data } = props
  const defaultCount = 50
  const maxCount = 1000000
  const defaultBatchSize = 10
  const maxBatchSize = 1000
  const classes = useStyles()

  const [openSbInput, setOpenSbInput] = useState(false)
  const [environment, setEnvironment] = useState(null)
  const [envChanged, setEnvChanged] = useState(false)
  const [topic, setTopic] = useState(null)

  const [count, setCount] = useState(defaultCount)
  const [countError, setCountError] = useState(false)
  const [countHelperText, setCountHelperText] = useState(null)

  const [batchSize, setBatchSize] = useState(defaultBatchSize)
  const [batchSizeError, setBatchSizeError] = useState(false)
  const [batchSizeHelperText, setBatchSizeHelperText] = useState(null)

  const handleCountChange = (e) => {
    setCount(e.target.value)
    setCountError(false)
    setCountHelperText(null)
    if (e.target.value <= 0) {
      setCountError(true)
      setCountHelperText('Must be posetive and greater than 0')
    }
    if (e.target.value > maxCount) {
      setCountError(true)
      setCountHelperText(`Must be less than ${maxCount}`)
    }
  }

  const handleBatchSizeChange = (e) => {
    setBatchSize(e.target.value)
    setBatchSizeError(false)
    setBatchSizeHelperText(null)
    if (e.target.value <= 0) {
      setBatchSizeError(true)
      setBatchSizeHelperText('Must be posetive and greater than 0')
    }
    if(count > 0 && e.target.value > count){
      setBatchSizeError(true)
      setBatchSizeHelperText(`Must be less than Count`)
    }
    if (e.target.value > maxBatchSize) {
      setBatchSizeError(true)
      setBatchSizeHelperText(`Must be less than ${maxBatchSize}`)
    }
  }

  const handleEnvChange = (event) => {
    setEnvironment(event.target.value)
    setEnvChanged(true)
    props.getTopics(event.target.value)
  }

  const handleTopicChange = (event) => {
    setTopic(event.target.value)
  }

  const handleSbClick = () => {
    setOpenSbInput(true)
    setEnvironment(null)
    setTopic(null)
    setCount(defaultCount)
    setBatchSize(defaultBatchSize)
    props.getEnvironments()
  }

  const handleClose = () => {
    setOpenSbInput(false)
    setEnvironment(null)
    setTopic(null)
    setCount(defaultCount)
    setBatchSize(defaultBatchSize)
  }

  const handleSendMessage = () => {
    const reqBody = {
      projectId: data.projectId,
      templateId: data.key,
      count: Number(count),
      batchSize: Number(batchSize),
      topicName: topic,
      environment: environment,
    }
    
    props.sendMessage(reqBody)
  }
   
  const sendMessageProgress = (
    <Backdrop className={classes.backdrop} open={props.sendMessageInprogress}>
      <CircularProgress color="inherit" />
      <Typography color="inherit">&nbsp;&nbsp;Sending Messages... </Typography>
    </Backdrop>
    )
  

  const sbInputDialog = (
    <Dialog open={openSbInput} onClose={handleClose}>
      <AppBar className={classes.templateAppBar} position="static">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
          {data.name}
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon></CloseIcon>
          </IconButton>
        </Toolbar>
      </AppBar>
      {props.getEnvCompleted || props.getTopicCompleted ? (
        <DialogContent>
          <DialogContentText>
            To send messages into ServiceBus, please enter the required details
            here.
          </DialogContentText>
          <Grid container spacing={3} className={classes.content}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="env-select-label">Environment</InputLabel>
                <Select
                  labelId="env-select-label"
                  id="env-select"
                  fullWidth
                  value={environment}
                  onChange={handleEnvChange}
                  disabled={!props.getEnvCompleted}
                >
                  {props.getEnvCompleted
                    ? props.environments.data.map((item) => {
                        return <MenuItem value={item}>{item}</MenuItem>
                      })
                    : null}
                </Select>
                {!props.getEnvCompleted && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="topic-select-label">Topic</InputLabel>
                <Select
                  labelId="topic-select-label"
                  id="topic-select"
                  fullWidth
                  value={topic}
                  onChange={handleTopicChange}
                  disabled={!props.getTopicCompleted}
                >
                  {props.getTopicCompleted
                    ? props.topics.data.map((item) => {
                        return <MenuItem value={item}>{item}</MenuItem>
                      })
                    : null}
                </Select>
                {envChanged && !props.getTopicCompleted && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={countError}
                margin="dense"
                id="count"
                label="Count"
                type="number"
                value={count}
                fullWidth
                variant="standard"
                helperText={countHelperText}
                onChange={handleCountChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={batchSizeError}
                margin="dense"
                id="batchSize"
                label="Batch Size"
                type="number"
                value={batchSize}
                fullWidth
                variant="standard"
                helperText={batchSizeHelperText}
                onChange={handleBatchSizeChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
      ) : (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
      <DialogActions>
        <Button
          size="small"
          color="secondary"
          variant="outlined"
          onClick={handleClose}
        >
          Close
        </Button>
        <Button
          size="small"
          color="primary"
          variant="outlined"
          onClick={handleSendMessage}
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  )

  return data.type === 'wiremock' ? null : (
    <div>
      <Tooltip
        title="Click to send messages into Service Bus"
        placement="bottom"
      >
        <Button
          variant="outlined"
          size="small"
          color="primary"
          endIcon={<FileCopyOutlinedIcon />}
          onClick={handleSbClick}
          className={classes.button}
        >
          SEND
        </Button>
      </Tooltip>
      {sbInputDialog}
      {sendMessageProgress}
    </div>
  )
}

export default ServiceBus
