import { reducerConst } from '../../config'

export function environmentReducer(state = {}, action) {
  switch (action.type) {
    case reducerConst.GET_ENVIRONMENTS:
      return { ...state, completed: false, environments: null, error: null }
    case reducerConst.GET_ENVIRONMENTS_SUCCESS:
      return { ...state, completed: true, environments: action.payload }
    case reducerConst.GET_ENVIRONMENTS_FAILURE:
      return { ...state, completed: true, error: action.payload }
    default:
      return state
  }
}

export function topicReducer(state = {}, action) {
  switch (action.type) {
    case reducerConst.GET_TOPICS:
      return { ...state, completed: false, topics:null, error: null }
    case reducerConst.GET_TOPICS_SUCCESS:
      return { ...state, completed: true, topics: action.payload }
    case reducerConst.GET_TOPICS_FAILURE:
      return { ...state, completed: true, error: action.payload }
    default:
      return state
  }
}

export function sendMessageReducer(state = {}, action) {
  switch (action.type) {
    case reducerConst.SEND_MESSAGES:
      return { ...state, inprogress: true, data: null, error: null }
    case reducerConst.SEND_MESSAGES_SUCCESS:
      return { ...state, inprogress: false, data: action.payload }
    case reducerConst.SEND_MESSAGES_FAILURE:
      return { ...state, inprogress: false, error: action.payload }
    default:
      return state
  }
}
