import {
  getTemplatesApi,
  getProjectsApi,
  addTemplateApi,
  updateTemplateApi,
  addNewProjectApi,
  deleteTemplateApi,
  getWMMappingByIdApi,
  addNewWMMappingApi,
  updateWMMappingApi,
  deleteWMMappingApi,
} from '../../api/api-details'

import { alertActions } from '../../app-common/common-components/app-alert/alert-action'
import { reducerConst } from '../../config'

export function getAllTemplates(projectId) {
  return (dispatch) => {
    dispatch({ type: reducerConst.GET_TEMPLATES })
    setTimeout(() => {
      getTemplatesApi(projectId)
        .then((data) => {
          dispatch(getAllTemplatesSuccess(data))
        })
        .catch((error) => {
          dispatch(getAllTemplatesError(error))
          dispatch(alertActions.error(error))
        })
    }, 100)
  }
  function getAllTemplatesSuccess(data) {
    return {
      type: reducerConst.GET_TEMPLATES_SUCCESS,
      payload: data,
    }
  }
  function getAllTemplatesError(error) {
    return {
      type: reducerConst.GET_TEMPLATES_FAILURE,
      payload: error,
    }
  }
}

export function getAllProjects() {
  return (dispatch) => {
    dispatch({ type: reducerConst.GET_PROJECTS })
    setTimeout(() => {
      getProjectsApi()
        .then((data) => {
          dispatch(getAllProjectSuccess(data))
        })
        .catch((error) => {
          dispatch(getAllProjectError(error))
          dispatch(alertActions.error(error))
        })
    }, 100)
  }
  function getAllProjectSuccess(data) {
    return {
      type: reducerConst.GET_PROJECTS_SUCCESS,
      payload: data,
    }
  }
  function getAllProjectError(error) {
    return {
      type: reducerConst.GET_PROJECTS_FAILURE,
      payload: error,
    }
  }
}

export function addNewTemplate(projectId, payload) {
  return (dispatch) => {
    dispatch({ type: reducerConst.ADD_TEMPLATE })
    setTimeout(() => {
      addTemplateApi(projectId, payload)
        .then((data) => {
          dispatch(addTemplateSuccess(data))
          dispatch(getAllTemplates(projectId))
          dispatch(alertActions.success(data.data))
        })
        .catch((error) => {
          dispatch(addTemplateError(error))
          dispatch(alertActions.error(error))
        })
    }, 100)
  }
  function addTemplateSuccess(data) {
    return {
      type: reducerConst.ADD_TEMPLATE_SUCCESS,
      payload: data,
    }
  }
  function addTemplateError(error) {
    return {
      type: reducerConst.ADD_TEMPLATE_FAILURE,
      payload: error,
    }
  }
}

export function updateTemplate(projectId, templateId, payload) {
  return (dispatch) => {
    dispatch({ type: reducerConst.UPDATE_TEMPLATE })
    setTimeout(() => {
      updateTemplateApi(projectId, templateId, payload)
        .then((data) => {
          dispatch(updateTemplateSuccess(data))
          dispatch(getAllTemplates(projectId))
          dispatch(alertActions.success(data.data))
        })
        .catch((error) => {
          dispatch(updateTemplateError(error))
          dispatch(alertActions.error(error))
        })
    }, 100)
  }
  function updateTemplateSuccess(data) {
    return {
      type: reducerConst.UPDATE_TEMPLATE_SUCCESS,
      payload: data,
    }
  }
  function updateTemplateError(error) {
    return {
      type: reducerConst.UPDATE_TEMPLATE_FAILURE,
      payload: error,
    }
  }
}

export function deleteTemplate(projectId, templateId) {
  return (dispatch) => {
    dispatch({ type: reducerConst.DELETE_TEMPLATE })
    setTimeout(() => {
      deleteTemplateApi(projectId, templateId)
        .then((data) => {
          dispatch(deleteTemplateSuccess(data))
          dispatch(getAllTemplates(projectId))
          dispatch(alertActions.success(data.data))
        })
        .catch((error) => {
          dispatch(deleteTemplateError(error))
          dispatch(alertActions.error(error))
        })
    }, 100)
  }
  function deleteTemplateSuccess(data) {
    return {
      type: reducerConst.DELETE_TEMPLATE_SUCCESS,
      payload: data,
    }
  }
  function deleteTemplateError(error) {
    return {
      type: reducerConst.DELETE_TEMPLATE_FAILURE,
      payload: error,
    }
  }
}

export function addNewProject(payload) {
  return (dispatch) => {
    dispatch({ type: reducerConst.ADD_PROJECT })
    setTimeout(() => {
      addNewProjectApi(payload)
        .then((data) => {
          dispatch(addProjectSuccess(data))
          dispatch(alertActions.success(data.data))
          dispatch(getAllProjects())
        })
        .catch((error) => {
          dispatch(addProjectError(error))
          dispatch(alertActions.error(error))
        })
    }, 100)
  }
  function addProjectSuccess(data) {
    return {
      type: reducerConst.ADD_PROJECT_SUCCESS,
      payload: data,
    }
  }
  function addProjectError(error) {
    return {
      type: reducerConst.ADD_PROJECT_FAILURE,
      payload: error,
    }
  }
}

export function getWMMappingById(id) {
  return (dispatch) => {
    dispatch({ type: reducerConst.GET_WM_MAPPING_ID })
    setTimeout(() => {
      getWMMappingByIdApi(id)
        .then((data) => {
          dispatch(getWMMappingByIdSuccess(data.data))
        })
        .catch((error) => {
          dispatch(getWMMappingByIdError(error))
          dispatch(alertActions.error(error))
        })
    }, 100)
  }
  function getWMMappingByIdSuccess(data) {
    return {
      type: reducerConst.GET_WM_MAPPING_ID_SUCCESS,
      payload: data,
    }
  }
  function getWMMappingByIdError(error) {
    return {
      type: reducerConst.GET_WM_MAPPING_ID_FAILURE,
      payload: error,
    }
  }
}

export function addNewWMMapping(projectId, payload) {
  return (dispatch) => {
    dispatch({ type: reducerConst.ADD_WM_MAPPING })
    setTimeout(() => {
      addNewWMMappingApi(projectId, payload)
        .then((data) => {
          dispatch(addNewWMMappingSuccess(data))
          dispatch(alertActions.success(data.data))
          dispatch(getAllTemplates(projectId))
        })
        .catch((error) => {
          dispatch(addNewWMMappingError(error))
          dispatch(alertActions.error(error))
        })
    }, 100)
  }
  function addNewWMMappingSuccess(data) {
    return {
      type: reducerConst.ADD_WM_MAPPING_SUCCESS,
      payload: data,
    }
  }
  function addNewWMMappingError(error) {
    return {
      type: reducerConst.ADD_WM_MAPPING_FAILURE,
      payload: error,
    }
  }
}

export function updateWMMapping(projectId, templateId, mappingId, payload) {
  return (dispatch) => {
    dispatch({ type: reducerConst.UPDATE_WM_MAPPING })
    setTimeout(() => {
      updateWMMappingApi(projectId, templateId, mappingId, payload)
        .then((data) => {
          dispatch(updateWMMappingSuccess(data))
          dispatch(alertActions.success(data.data))
          dispatch(getAllTemplates(projectId))
        })
        .catch((error) => {
          dispatch(updateWMMappingError(error))
          dispatch(alertActions.error(error))
        })
    }, 100)
  }
  function updateWMMappingSuccess(data) {
    return {
      type: reducerConst.UPDATE_WM_MAPPING_SUCCESS,
      payload: data,
    }
  }
  function updateWMMappingError(error) {
    return {
      type: reducerConst.UPDATE_WM_MAPPING_FAILURE,
      payload: error,
    }
  }
}

export function deleteWMMapping(projectId, templateId, mappingId) {
  return (dispatch) => {
    dispatch({ type: reducerConst.DELETE_WM_MAPPING })
    setTimeout(() => {
      deleteWMMappingApi(projectId, templateId, mappingId)
        .then((data) => {
          dispatch(deleteWMMappingSuccess(data))
          dispatch(getAllTemplates(projectId))
          dispatch(alertActions.success(data.data))
        })
        .catch((error) => {
          dispatch(deleteWMMappingError(error))
          dispatch(alertActions.error(error))
        })
    }, 100)
  }
  function deleteWMMappingSuccess(data) {
    return {
      type: reducerConst.DELETE_WM_MAPPING_SUCCESS,
      payload: data,
    }
  }
  function deleteWMMappingError(error) {
    return {
      type: reducerConst.DELETE_WM_MAPPING_FAILURE,
      payload: error,
    }
  }
}
