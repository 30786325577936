export function getTemplatesSelector(templates) {
  if (templates.completed && !templates.templates.data.errors) {
    const templateData = Object.entries(templates.templates.data).map(
      ([key, value]) => {
        return {
          key: key,
          name: value.templateName,
          projectId: value.projectId,
          template: value.template,
          type: value.templateType,
        }
      }
    )
    return templateData
  } else return []
}
