import { combineReducers } from 'redux'
import { alert } from '../common-components/app-alert/alert-reducer'
import {
  templateReducer,
  projectReducer,
  addTemplateReducer,
  updateTemplateReducer,
  addProjectReducer,
  deleteTemplateReducer,
  wmMappingReducer,
  addWMMappingReducer,
  updateWMMappingReducer,
  deleteWMMappingReducer,
} from '../../views/dashboard/reducer'

import {
  environmentReducer,
  topicReducer,
  sendMessageReducer,
} from '../../views/servicebus/reducer'

const rootReducer = combineReducers({
  alert,

  templateReducer,
  projectReducer,
  addTemplateReducer,
  updateTemplateReducer,
  addProjectReducer,
  deleteTemplateReducer,
  wmMappingReducer,
  addWMMappingReducer,
  updateWMMappingReducer,
  deleteWMMappingReducer,

  environmentReducer,
  topicReducer,
  sendMessageReducer,
})

export default rootReducer
