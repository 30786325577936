import React from 'react'
import { Typography } from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Grid from '@material-ui/core/Grid'
import WMMapping from './wm-ui'
import JsonMapping from './wm-json'
import ErrorIcon from '@material-ui/icons/Error'
import { config, wiremockMappingPlaceholder } from '../../config'

function BaseWMMapping(props) {
  const [updatedMapping, setUpdatedMapping] = React.useState(null)
  const [updatedJsonMapping, setUpdatedJsonMapping] = React.useState(null)
  const [jsonView, setJsonView] = React.useState(false)
  const [invalidJson, setInvalidJson] = React.useState(false)

  const toggleJsonView = (event) => {
    setJsonView(event.target.checked)
  }

  const createMappingObj = (mapping) => {
    let reqHeadersArr = mapping.request.headers
    let resHeadersArr = mapping.response.headers
    let reqHeadersObj = {}
    let resHeadersObj = {}

    reqHeadersArr.forEach((item) => {
      if (item.header && item.value) {
        reqHeadersObj = { ...reqHeadersObj, [item.header]: item.value }
      }
    })

    resHeadersArr.forEach((item) => {
      if (item.header && item.value) {
        resHeadersObj = { ...resHeadersObj, [item.header]: item.value }
      }
    })

    mapping.request.headers = reqHeadersObj
    mapping.response.headers = resHeadersObj
    return mapping
  }

  const transformMapping = (mapping) => {
    let transResHeaders =
      Object.keys(mapping.response.headers).length === 0
        ? []
        : Object.entries(mapping.response.headers).map(
            ([key, value], index, arr) =>
              (arr[index] = { header: key, value: value })
          )

    let transReqHeaders =
      Object.keys(mapping.request.headers).length === 0
        ? []
        : Object.entries(mapping.request.headers).map(
            ([key, value], index, arr) =>
              (arr[index] = { header: key, value: value })
          )

    const map = {
      ...mapping,
      response: {
        ...mapping.response,
        headers: transResHeaders,
      },
      request: {
        ...mapping.request,
        headers: transReqHeaders,
      },
    }
    return map
  }

  const handleMappingRequest = (mapping, item, val) => {
    if (item === config.wiremockHeaders) {
      const { action, index, prop, value } = val
      if (action === config.wiremockHeadersAdd) {
        const newHeader = {
          header: '',
          value: '',
        }
        mapping.request.headers.push(newHeader)
        setUpdatedMapping({ ...mapping })
        //deep copy the mapping object
        const mappingObj = createMappingObj(
          JSON.parse(JSON.stringify({ ...mapping }))
        )
        props.setWiremockMapping(mappingObj)
      }
      if (action === config.wiremockHeadersUpdate) {
        let header = mapping.request.headers[index]
        header = { ...header, [prop]: value }
        mapping.request.headers[index] = header
        setUpdatedMapping({ ...mapping })
        //deep copy the mapping object
        const mappingObj = createMappingObj(
          JSON.parse(JSON.stringify({ ...mapping }))
        )
        props.setWiremockMapping(mappingObj)
      }
      if (action === config.wiremockHeadersDelete) {
        mapping.request.headers.splice(index, 1)
        setUpdatedMapping({ ...mapping })
        //deep copy the mapping object
        const mappingObj = createMappingObj(
          JSON.parse(JSON.stringify({ ...mapping }))
        )
        props.setWiremockMapping(mappingObj)
      }
    } else {
      setUpdatedMapping({
        ...mapping,
        request: { ...mapping.request, [item]: val },
      })
      //deep copy the mapping object
      const mappingObj = createMappingObj(
        JSON.parse(
          JSON.stringify({
            ...mapping,
            request: { ...mapping.request, [item]: val },
          })
        )
      )
      props.setWiremockMapping(mappingObj)
    }
  }

  const handleMappingResponse = (mapping, item, val) => {
    if (item === config.wiremockHeaders) {
      const { action, index, prop, value } = val
      if (action === config.wiremockHeadersAdd) {
        const newHeader = {
          header: '',
          value: '',
        }
        mapping.response.headers.push(newHeader)
        setUpdatedMapping({ ...mapping })
        //deep copy the mapping object
        const mappingObj = createMappingObj(
          JSON.parse(JSON.stringify({ ...mapping }))
        )
        props.setWiremockMapping(mappingObj)
      }
      if (action === config.wiremockHeadersUpdate) {
        let header = mapping.response.headers[index]
        header = { ...header, [prop]: value }
        mapping.response.headers[index] = header
        setUpdatedMapping({ ...mapping })
        //deep copy the mapping object
        const mappingObj = createMappingObj(
          JSON.parse(JSON.stringify({ ...mapping }))
        )
        props.setWiremockMapping(mappingObj)
      }
      if (action === config.wiremockHeadersDelete) {
        mapping.response.headers.splice(index, 1)
        setUpdatedMapping({ ...mapping })
        //deep copy the mapping object
        const mappingObj = createMappingObj(
          JSON.parse(JSON.stringify({ ...mapping }))
        )
        props.setWiremockMapping(mappingObj)
      }
    } else {
      setUpdatedMapping({
        ...mapping,
        response: { ...mapping.response, [item]: val },
      })
      const mappingObj = createMappingObj(
        JSON.parse(
          JSON.stringify({
            ...mapping,
            response: { ...mapping.response, [item]: val },
          })
        )
      )
      props.setWiremockMapping(mappingObj)
    }
  }

  const handleJsonViewChnage = (code) => {
    setUpdatedJsonMapping(code)
    try {
      let objMapping = JSON.parse(code)
      if (objMapping && typeof objMapping === 'object') {
        props.setWiremockMapping(objMapping)
        setInvalidJson(false)
      }
    } catch (e) {
      props.setWiremockMapping(null)
      setInvalidJson(true)
    }
  }

  return (
    <div>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={4}
      >
        <Grid item>
          <span>
            {invalidJson ? (
              <Typography variant="caption" display="block" color="secondary">
                <Grid container direction="row" alignItems="center">
                  <ErrorIcon color="secondary" size="small" />
                  Not a valid wiremock mapping Json
                </Grid>
              </Typography>
            ) : null}
          </span>
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Switch
                checked={jsonView}
                onChange={toggleJsonView}
                name="jsonview"
                color="primary"
              />
            }
            label={<Typography variant="body2">JSON</Typography>}
          />
        </Grid>
      </Grid>
      <Grid>
        {props.addMapping ? (
          <div>
            {jsonView ? (
              <JsonMapping
                mapping={
                  updatedJsonMapping
                    ? updatedJsonMapping
                    : JSON.stringify(wiremockMappingPlaceholder, null, 2)
                }
                handleJsonViewChnage={handleJsonViewChnage}
              ></JsonMapping>
            ) : (
              <WMMapping
                mapping={
                  updatedMapping
                    ? updatedMapping
                    : transformMapping(wiremockMappingPlaceholder)
                }
                handleMappingRequest={handleMappingRequest}
                handleMappingResponse={handleMappingResponse}
              ></WMMapping>
            )}
          </div>
        ) : (
          <div>
            {jsonView ? (
              <JsonMapping
                mapping={
                  updatedJsonMapping
                    ? updatedJsonMapping
                    : JSON.stringify(props.mapping, null, 2)
                }
                handleJsonViewChnage={handleJsonViewChnage}
              ></JsonMapping>
            ) : (
              <WMMapping
                mapping={
                  updatedMapping
                    ? updatedMapping
                    : transformMapping(props.mapping)
                }
                handleMappingRequest={handleMappingRequest}
                handleMappingResponse={handleMappingResponse}
              ></WMMapping>
            )}
          </div>
        )}
      </Grid>
    </div>
  )
}

export default BaseWMMapping
