import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { Tooltip } from '@material-ui/core'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'

const useStyles = makeStyles((theme) => ({}))

function Sftp(props) {
  const { data } = props
  const classes = useStyles()

  const doSFTP = () => {
    alert('SFTP is not impemented')
  }

  return data.type === 'wiremock' ? null : (
    <Tooltip title="Specify the count and SFTP" placement="bottom">
      <Button
        variant="outlined"
        size="small"
        color="primary"
        endIcon={<FileCopyOutlinedIcon />}
        onClick={doSFTP}
        className={classes.button}
      >
        SEND
      </Button>
    </Tooltip>
  )
}

export default Sftp
