import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: '50%',
  },
}))

export default function Progress() {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <CircularProgress></CircularProgress>
    </div>
  )
}
