export default function handleError(error) {
  const { status, data } = error.response
  let errorMsg = ''
  switch (status) {
    case 400: {
      errorMsg = data.errors
      break
    }
    case 401: {
      errorMsg = error.message
      break
    }
    case 403: {
      errorMsg = error.message
      break
    }
    case 500: {
      errorMsg = error.message
      break
    }
    default: {
      errorMsg = error.message
      break
    }
  }
  return errorMsg
}
