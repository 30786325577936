import React from 'react'
import { alertActions } from './alert-action'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'

function Alert(props) {
  return <MuiAlert elevation={6} varient="filled" {...props} />
}

export default function AppAlert(props) {
  const { alert } = props
  const handleClose = () => {
    const { dispatch } = props
    dispatch(alertActions.clear())
  }

  return (
    <div>
      {alert.message ? (
        <Snackbar
          open={alert.message ? true : false}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={handleClose} severity={alert.type}>
            {Array.isArray(alert.message)
              ? alert.message.length == 1
                ? alert.message[0]
                : alert.message.map((item) => (
                    <span>
                      <li>{item}</li>
                    </span>
                  ))
              : alert.message}
          </Alert>
        </Snackbar>
      ) : null}
    </div>
  )
}
