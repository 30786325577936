import { get, file, post, put, del } from './api-ulility/apiutility'
import axios from 'axios'
import { config } from '../config'

const apiRoot = config.stubSvrUrl

export const getTemplatesApi = (projectId) =>
  get(`${apiRoot}/projects/${projectId}/templates`)
export const getProjectsApi = () => get(`${apiRoot}/projects`)
export const addNewProjectApi = (payload) =>
  post(`${apiRoot}/projects`, payload)
export const getDummyDataApi = (projectId, templateId) =>
  get(`${apiRoot}/projects/${projectId}/templates/${templateId}/dummyfile`)
export const addTemplateApi = (projectId, payload) =>
  post(`${apiRoot}/projects/${projectId}/templates`, payload)
export const updateTemplateApi = (projectId, templateId, payload) =>
  put(`${apiRoot}/projects/${projectId}/templates/${templateId}`, payload)
export const deleteTemplateApi = (projectId, templateId) =>
  del(`${apiRoot}/projects/${projectId}/templates/${templateId}`)

export const downloadHighVolFileApi = (
  projectId,
  tempplateId,
  tempplateType,
  count
) =>
  file(
    `${apiRoot}/projects/${projectId}/templates/${tempplateId}/download/${tempplateType}/${count}`
  )
export const downloadLowVolFileUrl = (
  projectId,
  tempplateId,
  tempplateType,
  count
) =>
  `${apiRoot}/projects/${projectId}/templates/${tempplateId}/download/${tempplateType}/${count}`

export const downloadLowVolFileApi = (
  projectId,
  tempplateId,
  tempplateType,
  count
) =>
  get(
    `${apiRoot}/projects/${projectId}/templates/${tempplateId}/file/${tempplateType}/${count}`
  )
export const downloadChunkDataApi = (
  projectId,
  tempplateId,
  tempplateType,
  count
) =>
  axios.get(
    `${apiRoot}/projects/${projectId}/templates/${tempplateId}/chunk/${tempplateType}/${count}`
  )
export const downloadDataApi = (projectId, tempplateId, tempplateType, count) =>
  `${apiRoot}/projects/${projectId}/templates/${tempplateId}/data/${tempplateType}/${count}`

export const getWMMappingByIdApi = (id) =>
  get(`${apiRoot}/projects/wiremock/mappings/${id}`)
export const addNewWMMappingApi = (projectId, payload) =>
  post(`${apiRoot}/projects/${projectId}/wiremock/mappings`, payload)
export const updateWMMappingApi = (projectId, templateId, mappingId, payload) =>
  put(
    `${apiRoot}/projects/${projectId}/templates/${templateId}/wiremock/mappings/${mappingId}`,
    payload
  )
export const deleteWMMappingApi = (projectId, templateId, mappingId) =>
  del(
    `${apiRoot}/projects/${projectId}/templates/${templateId}/wiremock/mappings/${mappingId}`
  )

export const getEnvironmentsApi = () =>
  get(`${apiRoot}/servicebus/environments`)

export const getSbTopicListApi = (env) =>
  get(`${apiRoot}/servicebus/${env}/topics`)

export const sendMessageToServiceBusApi = (message) =>
  post(`${apiRoot}/servicebus/sendmessage`, message)

const apis = {
  getTemplatesApi,
  getProjectsApi,
  addNewProjectApi,
  addTemplateApi,
  getDummyDataApi,
  updateTemplateApi,
  deleteTemplateApi,

  downloadHighVolFileApi,
  downloadLowVolFileUrl,
  downloadLowVolFileApi,
  downloadChunkDataApi,
  downloadDataApi,

  getWMMappingByIdApi,
  addNewWMMappingApi,
  updateWMMappingApi,
  deleteWMMappingApi,

  getEnvironmentsApi,
  getSbTopicListApi,
  sendMessageToServiceBusApi,
}

export default apis
