import {
  getEnvironmentsApi,
  getSbTopicListApi,
  sendMessageToServiceBusApi,
} from '../../api/api-details'

import { alertActions } from '../../app-common/common-components/app-alert/alert-action'
import { reducerConst } from '../../config'

export function getEnvironments() {
  return (dispatch) => {
    dispatch({ type: reducerConst.GET_ENVIRONMENTS })
    setTimeout(() => {
      getEnvironmentsApi()
        .then((data) => {
          dispatch(getEnvironmentsSuccess(data))
        })
        .catch((error) => {
          dispatch(getEnvironmentsError(error))
          dispatch(alertActions.error(error))
        })
    }, 100)
  }
  function getEnvironmentsSuccess(data) {
    return {
      type: reducerConst.GET_ENVIRONMENTS_SUCCESS,
      payload: data,
    }
  }
  function getEnvironmentsError(error) {
    return {
      type: reducerConst.GET_ENVIRONMENTS_FAILURE,
      payload: error,
    }
  }
}

export function getTopics(environment) {
  return (dispatch) => {
    dispatch({ type: reducerConst.GET_TOPICS })
    setTimeout(() => {
      getSbTopicListApi(environment)
        .then((data) => {
          dispatch(getTopicsSuccess(data))
        })
        .catch((error) => {
          dispatch(getTopicsError(error))
          dispatch(alertActions.error(error))
        })
    }, 100)
  }
  function getTopicsSuccess(data) {
    return {
      type: reducerConst.GET_TOPICS_SUCCESS,
      payload: data,
    }
  }
  function getTopicsError(error) {
    return {
      type: reducerConst.GET_TOPICS_FAILURE,
      payload: error,
    }
  }
}

export function sendMessagesToSb(reqBody) {
  return (dispatch) => {
    dispatch({ type: reducerConst.SEND_MESSAGES })
    setTimeout(() => {
      sendMessageToServiceBusApi(reqBody)
        .then((data) => {
          dispatch(sendMessagesToSbSuccess(data))
          dispatch(alertActions.success(data.data))
        })
        .catch((error) => {
          dispatch(sendMessagesToSbError(error))
          dispatch(alertActions.error(error))
        })
    }, 600)
  }
  function sendMessagesToSbSuccess(data) {
    return {
      type: reducerConst.SEND_MESSAGES_SUCCESS,
      payload: data,
    }
  }
  function sendMessagesToSbError(error) {
    return {
      type: reducerConst.SEND_MESSAGES_FAILURE,
      payload: error,
    }
  }
}
