import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { InputAdornment, Tooltip } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import SaveAltOutlinedIcon from '@material-ui/icons/SaveAltOutlined'
import CircularProgress from '@material-ui/core/CircularProgress'
import { green } from '@material-ui/core/colors'
import {
  downloadHighVolFileApi,
  downloadLowVolFileApi,
} from '../../api/api-details'

const useStyles = makeStyles((theme) => ({
  input: {
    width: 250,
  },
  button: {
    width: 80,
  },
  divider: {
    height: 28,
    marginRight: 12,
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}))

function Download(props) {
  const defaultCount = 10
  const classes = useStyles()
  const [count, setCount] = useState(defaultCount)
  const [error, setError] = useState(false)
  const [progress, setProgress] = useState(false)
  const [helperText, setHelperText] = useState(null)
  const maxCount = 3000000
  const minCount = 100000
  const { data } = props

  const handleCountChange = (e) => {
    setCount(e.target.value)
    setError(false)
    setHelperText(null)
    if (e.target.value <= 0) {
      setError(true)
      setHelperText('Must be posetive and greater than 0')
    }
    if (e.target.value > maxCount) {
      setError(true)
      setHelperText(`Must be less than ${maxCount}`)
    }
  }

  const openResposeAsFile = (blobData, fileName) => {
    var blob = new Blob([blobData.data])
    const href = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = href
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(href)
  }

  const downloadData = () => {
    const tempId = data.key
    const projId = data.projectId
    const tempType = data.type
    const name = data.name
    setProgress(true)
    var startDate = new Date()

    if (count <= minCount) {
      let fileName = `${name}_${new Date()
        .toJSON()
        .slice(
          0,
          10
        )}-${new Date().getHours()}-${new Date().getMinutes()}-${new Date().getSeconds()}.${tempType}`
      setTimeout(() => {
        downloadLowVolFileApi(projId, tempId, tempType, count)
          .then((response) => {
            openResposeAsFile(response, fileName)

            setProgress(false)

            var endDate = new Date()
            var seconds = (endDate.getTime() - startDate.getTime()) / 1000

            props.successAlert(
              `${fileName} downloaded succesfully. ${count} records generated in ${seconds} seconds.`
            )
          })
          .catch((error) => {
            setProgress(false)
            props.errorAlert(error)
          })
      }, 240)
    } else {
      let fileName = `${name}_${new Date()
        .toJSON()
        .slice(
          0,
          10
        )}-${new Date().getHours()}-${new Date().getMinutes()}-${new Date().getSeconds()}.zip`
      setTimeout(() => {
        downloadHighVolFileApi(projId, tempId, tempType, count)
          .then((response) => {
            openResposeAsFile(response, fileName)

            setProgress(false)

            var endDate = new Date()
            var seconds = (endDate.getTime() - startDate.getTime()) / 1000

            props.successAlert(
              `${fileName} downloaded succesfully. ${count} records generated in ${seconds} seconds.`
            )
          })
          .catch((error) => {
            setProgress(false)
            props.errorAlert(error)
          })
      }, 240)
    }
  }

  return data.type === 'wiremock' ? null : (
    <TextField
      className={classes.input}
      error={error}
      required
      variant="outlined"
      id="count"
      name="count"
      type="number"
      value={count}
      size="small"
      onChange={handleCountChange}
      helperText={helperText}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Divider className={classes.divider} orientation="vertical" />
            <Tooltip
              title={`Specify the count and download the ${data.type} file`}
              placement="bottom"
            >
              <Button
                disabled={error || progress}
                size="small"
                color="primary"
                endIcon={
                  progress ? (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  ) : (
                    <SaveAltOutlinedIcon />
                  )
                }
                onClick={downloadData}
                className={classes.button}
              >
                {!progress && data.type}
              </Button>
            </Tooltip>
          </InputAdornment>
        ),
      }}
    />
  )
}

export default Download
