import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import { IconButton, Tooltip } from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'
import { config } from '../../config'

function Header(props) {
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={3}
    >
      <Grid item xs={3}>
        <TextField
          variant="outlined"
          id="key"
          name="key"
          label="Key"
          value={props.header}
          size="small"
          onChange={(e) =>
            props.handleHeaderChnage(props.mapping, config.wiremockHeaders, {
              action: config.wiremockHeadersUpdate,
              index: props.index,
              prop: 'header',
              value: e.target.value,
            })
          }
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          variant="outlined"
          id="value"
          name="value"
          label="Value"
          value={props.value}
          size="small"
          onChange={(e) =>
            props.handleHeaderChnage(props.mapping, config.wiremockHeaders, {
              action: config.wiremockHeadersUpdate,
              index: props.index,
              prop: 'value',
              value: e.target.value,
            })
          }
        />
      </Grid>
      <Grid item xs={3}>
        <IconButton
          size="small"
          onClick={() =>
            props.handleHeaderChnage(props.mapping, config.wiremockHeaders, {
              action: config.wiremockHeadersDelete,
              index: props.index,
            })
          }
          color="secondary"
        >
          <Tooltip title={`Remove ${props.header}`} placement="bottom">
            <CancelIcon />
          </Tooltip>
        </IconButton>
      </Grid>
    </Grid>
  )
}

export default Header
