import React from 'react'
import { connect } from 'react-redux'
import { history } from './app-common/history'
import { alertActions } from './app-common/common-components/app-alert/alert-action'
import AppAlert from './app-common/common-components/app-alert/alert'
import Dashboard from './views/dashboard'

class App extends React.Component {
  constructor(props) {
    super(props)
    const { dispatch } = this.props
    history.listen((location, action) => {
      dispatch(alertActions.clear())
    })
  }

  render() {
    const { alert, dispatch } = this.props
    return (
      <div>
        <AppAlert alert={alert} dispatch={dispatch}></AppAlert>
        <Dashboard />
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { alert } = state
  return {
    alert,
  }
}

export default connect(mapStateToProps)(App)
