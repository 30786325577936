import React from 'react'
import Editor from 'react-simple-code-editor'
import { highlight, languages } from 'prismjs/components/prism-core'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-javascript'
import 'prismjs/themes/prism.css'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Grid from '@material-ui/core/Grid'
import { makeStyles, InputAdornment } from '@material-ui/core'
import Header from './header'
import { config } from '../../config'

const useStyles = makeStyles((theme) => ({
  templateEditor: {
    fontFamily: '"Fira code", "Fira Mono", monospace',
    fontSize: 14,
  },
  divider: {
    marginTop: 15,
    marginBottom: 15,
  },
}))

function WMMapping(props) {
  const classes = useStyles()

  const reqHeaderList = (headers) => {
    return (
      <div>
        {headers.length === 0
          ? null
          : headers.map((item, index) => {
              return (
                <Header
                  header={item.header}
                  value={item.value}
                  index={index}
                  mapping={props.mapping}
                  headerType="request"
                  handleHeaderChnage={props.handleMappingRequest}
                ></Header>
              )
            })}
      </div>
    )
  }

  const resHeaderList = (headers) => {
    return (
      <div>
        {headers.length === 0
          ? null
          : headers.map((item, index) => {
              return (
                <Header
                  header={item.header}
                  value={item.value}
                  index={index}
                  mapping={props.mapping}
                  headerType="response"
                  handleHeaderChnage={props.handleMappingResponse}
                ></Header>
              )
            })}
      </div>
    )
  }

  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Request</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  variant="outlined"
                  id="method"
                  name="method"
                  label="Method"
                  value={props.mapping.request.method}
                  size="small"
                  onChange={(e) =>
                    props.handleMappingRequest(
                      props.mapping,
                      'method',
                      e.target.value
                    )
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  variant="outlined"
                  id="url"
                  name="url"
                  label="URL"
                  value={props.mapping.request.url.replace(
                    config.wiremockUrlPrefix,
                    ''
                  )}
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {config.wiremockUrlPrefix}
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) =>
                    props.handleMappingRequest(
                      props.mapping,
                      'url',
                      `${config.wiremockUrlPrefix}${e.target.value}`
                    )
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <br />
                <Button
                  size="small"
                  color="primary"
                  onClick={() =>
                    props.handleMappingRequest(
                      props.mapping,
                      config.wiremockHeaders,
                      {
                        action: config.wiremockHeadersAdd,
                      }
                    )
                  }
                >
                  Add Request Header
                </Button>
              </Grid>
              <Grid item xs={12}>
                {reqHeaderList(props.mapping.request.headers)}
              </Grid>
            </Grid>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Response</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  id="status"
                  name="status"
                  label="Status"
                  value={props.mapping.response.status}
                  size="small"
                  onChange={(e) =>
                    props.handleMappingResponse(
                      props.mapping,
                      'status',
                      e.target.value
                    )
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <br />
                <Button
                  size="small"
                  color="primary"
                  onClick={() =>
                    props.handleMappingResponse(
                      props.mapping,
                      config.wiremockHeaders,
                      {
                        action: config.wiremockHeadersAdd,
                      }
                    )
                  }
                >
                  Add Response Header
                </Button>
              </Grid>
              <Grid item xs={12}>
                {resHeaderList(props.mapping.response.headers)}
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <Typography variant="body2">Body</Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Editor
                  value={props.mapping.response.body}
                  onValueChange={(code) =>
                    props.handleMappingResponse(props.mapping, 'body', code)
                  }
                  highlight={(code) => highlight(code, languages.js)}
                  padding={10}
                  variant="outlined"
                  className={classes.templateEditor}
                ></Editor>
              </Grid>
            </Grid>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default WMMapping
